<template>
    <div>
       
        <v-row>
            <v-col>
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <datePickerForm
                    label="Próximo contacto"
                    :valueInput="values.next_date"
                    :rule="[]"
                    @valueChange="(v) => {values.next_date = v; delete serverErrors['next_date']}"
                    :errorMessages="serverErrors['next_date']"
                    data-cy="next_date-input"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textAreaForm
                    @valueChange="(v) => {values.comments = v}" 
                    :valueInput="values.comments" 
                    :rule="rules.required" 
                    label="Comentarios/Observaciones"
                    :errorMessages="serverErrors.comments"
                   
                    data-cy="comments-input"
                ></textAreaForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2" >
                <fileInputForm
                    label="Adjunto"
                    :multiple="false"
                    :valueInput="values.file_file"
                    :url="values.file_url"
                    :rule="[]"
                    @valueChange="(v) => {values.file_file = v; delete serverErrors.file_file}"
                    :errorMessages="serverErrors.file_file"
                    v-on:update:url="values.file_url = $event"
                    :disableDelete="false"
                    data-cy="file_file-input"
                ></fileInputForm>
            </v-col>
        </v-row>

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
           
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
				
            },

          
        }
    },
   
    mounted(){
    },
    methods: {
        

    }
}
</script>

<style>

</style>